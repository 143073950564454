import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Container, CircularProgress, Grid, TextField, Card,
  Table, TableHead, TableContainer, Paper, TableCell, TableBody,
  TableRow, Button, IconButton, Typography, Tooltip } from '@mui/material';
import { Utils } from '../../../../utils/UtilFunctions';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { ANALYSIS_COMPONENTS, MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PlanDetails = ({ planId, onSubmit, onBack }) => {
  const [planDetails, setPlanDetails] = useState({
    plan_id: '',
    plan_date: '',
    plan_tag: '',
    material_type: '',
    plan_data: []
  });
  const [showViewOrders, setshowViewOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    GET_PLAN_DETAILS: 'CPD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(planId)) {
      raiseGetPlanRequest(planId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getPlanDetailsForAnalysis && apiToken == APIToken.GET_PLAN_DETAILS ) {
      if (response.data != undefined) {
        const arr = response.data.plan_data.filter((plan) => plan.has_orders == true);
        setshowViewOrders(arr.length > 0);
        populatePlanData(response.data);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getPlanDetailsForAnalysis && apiToken == APIToken.GET_PLAN_DETAILS) {
      defaultMsg = 'Failed to retrieve plan list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetPlanRequest = (planId) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getPlanDetailsForAnalysis, params: params },
      APIToken.GET_PLAN_DETAILS, processSuccess, processError);
  };

  const populatePlanData = (data) => {
    const obj = {};
    data.plan_data.forEach((plan) => {
      const materialType = MATERIAL_TYPE[plan.material_type];
      if (materialType == 3) {
        const key = `${plan.item_id}_${plan.cate_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: plan.cate_name,
            cate_id: plan.cate_id,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: plan.model_name,
          brand_name: plan.brand_name,
          model_id: plan.model_id,
          brand_id: plan.brand_id,
          min_price: plan.min_price,
          max_price: plan.max_price,
          quantity: plan.quantity,
          plan_serial: plan.serial,
          has_orders: plan.has_orders
        });
      } else {
        const key = `${plan.item_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: plan.max_price,
          min_price: plan.min_price,
          quantity: plan.quantity,
          plan_serial: plan.serial,
          has_orders: plan.has_orders
        });
      }
    });
    const filteredData = Object.values(obj);
    setPlanDetails({
      ...planDetails,
      plan_id: data.plan_id,
      plan_date: validateStringForNull(data.plan_date) ? data.plan_date.display : '',
      plan_tag: validateStringForNull(data.plan_tag) ? data.plan_tag: '',
      plan_data: filteredData
    });
  };

  const handleViewOrdersClick = () => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.plan_orders,
      plan_id: planId
    };
    onSubmit(obj);
  };

  const handleOrdersByProductClick = (prodId) => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.orders_by_product,
      plan_id: planId,
      prod_id: prodId,
      material_type: planDetails.material_type
    };
    onSubmit(obj);
  };

  const handleOrdersBySerialClick = (serial) => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.orders_by_serial,
      plan_id: planId,
      plan_serial: serial
    };
    onSubmit(obj);
  };

  const doesPlanDataContainsOrders = (planData) => {
    if (planData == undefined) {
      return false;
    }
    const arr = planData.sub_data.filter((plan) => plan.has_orders == true);
    return arr.length > 0;
  };

  const getMaterialContent = (plan) => {
    switch (plan.material_type) {
    case 1:
    case 2:
      return getSilkPolyesterContent(plan);
    case 3:
      return getReadyMadeContent(plan);
    default:
      return 'Invalid Material type';
    }
  };

  const getReadyMadeContent = (plan) => {
    return (
      <Grid item xs={12} key={plan.id}>
        <Card elevation={12}>
          <Grid key={plan.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={4} sm={2}>
                <TextField
                  id="mark Name"
                  label="Mark Name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='standard'
                  value={plan.mark_name}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={8} sm={4}>
                <TextField
                  id="category Name"
                  label="Category Name"
                  name="cate_name"
                  autoComplete ='off'
                  variant='standard'
                  value={plan.cate_name}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={9} sm={4}>
                <TextField
                  id="product Name"
                  label="Product Name"
                  name="prod_name"
                  autoComplete ='off'
                  variant='standard'
                  value={plan.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              {doesPlanDataContainsOrders(plan) ? (
                <Grid item xs={3} sm={2} display='flex' justifyContent='flex-end'>
                  <Tooltip title='View Order By Product'>
                    <IconButton
                      color='primary'
                      onClick={() => handleOrdersByProductClick(plan.prod_id)}
                      sx={{ p: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : ''}
            </Grid>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Model Name</TableCell>
                    <TableCell>Brand Name</TableCell>
                    <TableCell>Min Price</TableCell>
                    <TableCell>Max Price</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plan.sub_data.map((planSubData, j) => (
                    <TableRow key={planSubData.id}>
                      <TableCell>{planSubData.model_name}</TableCell>
                      <TableCell>{planSubData.brand_name}</TableCell>
                      <TableCell>{planSubData.min_price}</TableCell>
                      <TableCell>{planSubData.max_price}</TableCell>
                      <TableCell>
                        {planSubData.has_orders == true ? (
                          <Tooltip title='Orders By Range'>
                            <Typography variant='h6' color='red'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                            >
                              {planSubData.quantity}
                            </Typography>
                          </Tooltip>
                        ) : planSubData.quantity }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getSilkPolyesterContent = (plan) => {
    return (
      <Grid item xs={12} key={plan.id}>
        <Card elevation={12}>
          <Grid container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={4} sm={2}>
                <TextField
                  id="mark Name"
                  label="Mark Name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='standard'
                  value={plan.mark_name}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={8} sm={4}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  value={plan.prod_id}
                  size='small'
                  disabled={true}
                  fullWidth
                  variant='standard'
                />
              </Grid>
              <Grid item xs={9} sm={4}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  value={plan.prod_name}
                  size='small'
                  fullWidth
                  variant='standard'
                  disabled={true}
                />
              </Grid>
              {doesPlanDataContainsOrders(plan) ? (
                <Grid item xs={3} sm={2} display='flex' justifyContent='flex-end'>
                  <Tooltip title='View Orders By Product'>
                    <IconButton
                      color='primary'
                      onClick={() => handleOrdersByProductClick(plan.prod_id)}
                      sx={{ p: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : ''}
            </Grid>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Min Price</TableCell>
                    <TableCell>Max Price</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plan.sub_data.map((planSubData, j) => (
                    <TableRow key={planSubData.id}>
                      <TableCell>{planSubData.min_price}</TableCell>
                      <TableCell>{planSubData.max_price}</TableCell>
                      <TableCell>
                        {planSubData.has_orders == true ? (
                          <Tooltip title='View Orders by Range'>
                            <Typography variant='h6' color='red'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                            >
                              {planSubData.quantity}
                            </Typography>
                          </Tooltip>
                        ) : planSubData.quantity }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Card>
      </Grid>
    );
  };


  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={1}>
          <Tooltip title='Back'>
            <IconButton
              color='primary'
              onClick={onBack}
              sx={{ p: 1 }}
            >
              < ArrowBackIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={11} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Analysis - Plan Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="Plan Id"
            label="Plan Id"
            name="plan_id"
            autoComplete ='off'
            value={planDetails.plan_id}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="Plan tag"
            label="Plan Tag"
            name="plan_tag"
            autoComplete ='off'
            value={planDetails.plan_tag}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="Plan Date"
            label="Plan Date"
            name="plan_date"
            autoComplete ='off'
            value={planDetails.plan_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        {showViewOrders == true ? (
          <Grid item xs={12} display='flex' justifyContent='flex-end'>
            <Button
              color="primary"
              variant="contained"
              size='small'
              onClick={handleViewOrdersClick}
            >
              View Orders
            </Button>
          </Grid>
        ) : ''}
        {planDetails.plan_data.length > 0 ?
          planDetails.plan_data.map((plan, index) => (
            <React.Fragment key={index}>
              {getMaterialContent(plan)}
            </React.Fragment>
          )) : '' }
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

PlanDetails.propTypes = {
  planId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default PlanDetails;
