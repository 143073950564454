import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Container, CircularProgress, Grid, TextField, Card, IconButton, Divider } from '@mui/material';
import { Utils } from '../../../../utils/UtilFunctions';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ViewPlanDetails = ({ planId }) => {
  const [planDetails, setPlanDetails] = useState({
    plan_id: '',
    plan_date: '',
    plan_tag: '',
    plan_data: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    VIEW_PLAN_DETAILS: 'CVPD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(planId)) {
      raiseGetPlanRequest(planId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getPlanDetails && apiToken == APIToken.VIEW_PLAN_DETAILS ) {
      if (response.data != undefined) {
        populatePlanData(response.data);
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve plan details');
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getPlanDetails && apiToken == APIToken.VIEW_PLAN_DETAILS) {
      defaultMsg = 'Failed to retrieve plan list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetPlanRequest = (planId) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getPlanDetails, params: params },
      APIToken.VIEW_PLAN_DETAILS, processSuccess, processError);
  };

  const populatePlanData = (data) => {
    const obj = {};
    data.plan_data.forEach((plan) => {
      const materialType = MATERIAL_TYPE[plan.material_type];
      if (materialType == 3 ) { // Readymade
        const key = `${plan.item_id}_${plan.cate_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: plan.cate_name,
            cate_id: plan.cate_id,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: plan.model_name,
          brand_name: plan.brand_name,
          model_id: plan.model_id,
          brand_id: plan.brand_id,
          min_price: plan.min_price,
          max_price: plan.max_price,
          quantity: plan.quantity
        });
      } else { // Silk and Polyester
        const key = `${plan.item_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: plan.max_price,
          min_price: plan.min_price,
          quantity: plan.quantity
        });
      }
    });
    const filteredData = Object.values(obj);
    setPlanDetails({
      ...planDetails,
      plan_id: data.plan_id,
      plan_date: validateStringForNull(data.plan_date) ? data.plan_date.display : '',
      plan_tag: validateStringForNull(data.plan_tag) ? data.plan_tag: '',
      plan_data: filteredData
    });
  };

  const handleShowPlanDetails = (id) => {
    setPlanDetails((prevPlanDetails) => ({
      ...prevPlanDetails,
      plan_data: prevPlanDetails.plan_data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_expanded: !item.is_expanded
          };
        }
        return item;
      })
    }));
  };

  const getMaterialContent = (plan) => {
    switch (plan.material_type) {
    case 1:
    case 2:
      return getSilkAndPolyesterContent(plan);
    case 3:
      return getReadyMadeContent(plan);
    default:
      return 'Invalid Material type';
    }
  };

  const getSilkAndPolyesterContent = (plan) => {
    return (
      <Grid item xs={12} key={plan.id}>
        <Card elevation={12}>
          <Grid container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.5} sm={1.2} md={1.1} lg={1.4}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px' }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.5} sm={5} lg={4.9}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  value={plan.prod_id}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={10.5} sm={5} lg={4.9}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  value={plan.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              {planDetails.plan_data.length != 0 ? (
                <Grid item xs={1.5} sm={0.8} lg={0.8} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  <IconButton color='primary' onClick={() => handleShowPlanDetails(plan.id)}>
                    {plan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                  </IconButton>
                </Grid>
              ) : ''}
            </Grid>
            {plan.is_expanded == true ? plan.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={6} sm={4}>
                  <TextField
                    id="min_price"
                    label="Min Price"
                    name="min_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.min_price}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextField
                    id="max_price"
                    label="Max Price"
                    name="max_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.max_price}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextField
                    id="quantity"
                    label="Quantity"
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.quantity}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getReadyMadeContent = (plan) => {
    return (
      <Grid item xs={12} key={plan.id}>
        <Card elevation={12}>
          <Grid key={plan.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.5} sm={1.2} md={1.1} lg={1.4}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px' }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.5} sm={5} lg={4.9} >
                <TextField
                  id="category Name"
                  label="Category Name"
                  name="cate_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.cate_name}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={10.5} sm={5} lg={4.9}>
                <TextField
                  id="product Name"
                  label="Product Name"
                  name="prod_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.prod_name}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              {planDetails.plan_data.length != 0 ? (
                <Grid item xs={1.5} sm={0.8} lg={0.8} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  <IconButton color='primary' onClick={() => handleShowPlanDetails(plan.id)} >
                    {plan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                  </IconButton>
                </Grid>
              ) : ''}
            </Grid>
            {plan.is_expanded == true ? plan.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="Model Name"
                    label="Model Name"
                    name="model_name"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.model_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="Brand Name"
                    label="Brand Name"
                    name="brand_name"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.brand_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="min_price"
                    label="Min Price"
                    name="min_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.min_price}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="max_price"
                    label="Max Price"
                    name="max_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.max_price}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="quantity"
                    label="Quantity"
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.quantity}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="Plan Id"
            label="Plan Id"
            name="plan_id"
            autoComplete ='off'
            value={planDetails.plan_id}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6.5}>
          <TextField
            id="Plan tag"
            label="Plan Tag"
            name="plan_tag"
            autoComplete ='off'
            value={planDetails.plan_tag}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <TextField
            id="Plan Date"
            label="Plan Date"
            name="plan_date"
            autoComplete ='off'
            value={planDetails.plan_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        {planDetails.plan_data.length > 0 ?
          planDetails.plan_data.map((plan, index) => (
            <React.Fragment key={index}>
              {getMaterialContent(plan)}
            </React.Fragment>
          )) : '' }
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

ViewPlanDetails.propTypes = {
  planId: PropTypes.string
};

export default ViewPlanDetails;
