import { Backdrop, CircularProgress, Container, Grid, Table, TableBody, Paper,
  TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import Services from '../../../../utils/Services';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import APIData from '../../../../utils/APIData';
import { ANALYSIS_COMPONENTS } from '../../../../utils/EnumDefinitions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { ORDERS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator';

const ActivePlanList = ({ onSubmit }) => {
  const purchaserId = CookieUtils.getUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [activePlanList, setActivePlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const APIToken = {
    GET_ACTIVE_PLAN_LIST: 'CAPL01',
    GET_USER: 'CAPL02'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getActivePlanList && apiToken == APIToken.GET_ACTIVE_PLAN_LIST ) {
      if (response.data != undefined) {
        setActivePlanList(response.data);
      }
      setIsLoading(false);
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      setIsLoading(false);
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'ORDERS');
        if (filtered != undefined && filtered.length > 0) {
          if (!validateFeature(filtered[0].available_features, ORDERS_FEATURES.ANALYSIS)) {
            showSnackBar('error', 'You don\'t have permission to analyze the orders');
          } else {
            if (validateStringForNull(purchaserId)) {
              raiseActivePlanList(purchaserId);
            }
          }
        }
      }
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getActivePlanList && apiToken == APIToken.GET_ACTIVE_PLAN_LIST) {
      defaultMsg = 'Failed to Get Active plan list';
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseActivePlanList = (purchaserId) => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getActivePlanList, params: params },
      APIToken.GET_ACTIVE_PLAN_LIST, processSuccess, processError);
  };

  const handleRowClick = (planId) => {
    setSelectedPlan(planId);
  };

  const handleViewPlanClick = () => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.plan_details,
      plan_id: selectedPlan.plan_id,
      material_type: selectedPlan.material_type
    };
    onSubmit(obj);
  };

  const handleViewOrdersClick = () => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.plan_orders,
      plan_id: selectedPlan.plan_id
    };
    onSubmit(obj);
  };

  const getPlanData = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Plan ID</TableCell>
              <TableCell>Plan Tag</TableCell>
              <TableCell>Percentage Covered</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activePlanList.map((item, index) => (
              <TableRow key={index}
                onClick={() => handleRowClick(item)} selected={selectedPlan === item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'rgb(183, 211, 249)'
                  }
                }}
              >
                <TableCell>{item.plan_id}</TableCell>
                <TableCell>{item.plan_tag}</TableCell>
                <TableCell>{validateStringForNull(item.percent_covered) ? item.percent_covered : 0}</TableCell>
                <TableCell sx={{ p: 0 }}>{selectedPlan.plan_id === item.plan_id ? (
                  <React.Fragment>
                    <Tooltip title='View Plan'>
                      <IconButton color='primary'
                        onClick={() => handleViewPlanClick()}
                      >
                        <VisibilityIcon/>
                      </IconButton>
                    </Tooltip>
                    {item.bought_quantity != undefined ? (
                      <Tooltip title='View Orders'>
                        <IconButton color='primary'
                          onClick={() => handleViewOrdersClick()}
                        >
                          <BackupTableIcon/>
                        </IconButton>
                      </Tooltip>
                    ) : ''}
                  </React.Fragment>
                ) : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
           Analysis - Active Plans
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {getPlanData()}
        </Grid>
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

ActivePlanList.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ActivePlanList;
