import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography
} from '@mui/material';
import {
  ChevronsLeft as ChevronsLeftIcon
} from 'react-feather';
import { CookieUtils, Utils } from '../../utils/UtilFunctions';
import { styled } from '@mui/system';
import Logo from '../components/Logo';
import { SideBarMenuItems } from '../../utils/MenuItems';
import APIData from '../../utils/APIData';
import Services from '../../utils/Services';
import { validateFeature } from '../../utils/FeatureValidator';

const AppSidebar = ({ appName, onClose, isOpen, appId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [allowedFeatures, setAllowedFeatures] = useState([]);
  const APIToken = {
    GET_USER: 'ASB01'
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const raiseGetUserRequest = () => {
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == appId);
        if (filtered != undefined && filtered.length > 0) {
          setAllowedFeatures(filtered[0].available_features);
        }
      }
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    console.log(err.message);
  };

  const getFilteredItems = () => {
    const filteredItems = SideBarMenuItems.getMenuItems(appName);
    return filteredItems;
  };

  useEffect(() => {
    if (isOpen && onClose) {
      onClose();
    }
  }, [location.pathname]);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }));

  const handleNavigation = (item) => {
    if (onClose != undefined) {
      onClose();
    }
    navigate(item.link);
  };

  const isMenuItemAllowed = (featuresList, permissionList, criteria) => {
    if (criteria == 'AND') {
      return permissionList.every((permission) => {
        return permission == '*' || validateFeature(featuresList, permission);
      });
    } else {
      return permissionList.some((permission) => {
        return permission == '*' || validateFeature(featuresList, permission);
      });
    }
  };

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={isOpen}
      variant="temporary"
      PaperProps={{
        sx: {
          width: 280
        }
      }}
    >
      <DrawerHeader sx={{ bgcolor: '#205295' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <Avatar
            component={RouterLink}
            sx={{
              cursor: 'pointer',
              width: 32,
              height: 32,
              ml: 1,
              bgcolor: 'white',
              color: '#205295'
            }}
            to="/my-account"
          >
            {Utils.getInitials(CookieUtils.getUserName())}
          </Avatar>
          <Typography
            sx={{ ml: 1, alignItems: 'center' }}
            color="white"
            variant="h5"
          >
            Welcome {CookieUtils.getUserName().charAt(0).toUpperCase() + CookieUtils.getUserName().slice(1)}
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ alignContent: 'right' }}>
          <ChevronsLeftIcon color='white' />
        </IconButton>
      </DrawerHeader>
      {allowedFeatures.length > 0 ? getFilteredItems().map((list, i) =>
        (isMenuItemAllowed(allowedFeatures, list.permissions, list.criteria ?? 'OR') ? (
          <List key={i} subheader={list.header != '' ? <ListSubheader>{list.header}</ListSubheader> : ''}>
            {list.items.map((item, j) => (
              isMenuItemAllowed(allowedFeatures, item.permissions, item.criteria ?? 'OR') ? (
                <ListItem key={j} disablePadding sx={{ display: 'block' }} onClick={() => {handleNavigation(item);}}>
                  <Tooltip title={open ? '' : item.name} placement="right">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center'
                        }}
                      >
                        <item.icon/>
                      </ListItemIcon>
                      <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ) :<React.Fragment key={j}/>))}
          </List>
        ) : <React.Fragment key={i}/>)) : ''}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: 2,
          py: 2
        }}>
        <Typography
          sx={{ mb: 1, display: 'flex', justifyContent: 'center' }}
          color="text.secondary"
          variant="h6"
        >
          Powered By
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <a href="http://www.teqshack.in" target="_">
            <Logo
              alt="Teqshack"
              src="/static/images/Teqshack.png"
              height={40}/>
          </a>
        </Box>
      </Box>
    </Drawer>
  );
};

AppSidebar.propTypes = {
  appName: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  appId: PropTypes.string
};

export { AppSidebar };
