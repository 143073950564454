import DashboardIcon from '@mui/icons-material/InsertChart';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { ORDERS_FEATURES, REPORTS_FEATURES } from './FeatureValidator';

const getReportsAppMenuItems = () => {
  return [
    {
      header: 'Home',
      permissions: ['*'],
      items: [
        {
          name: 'Dashboard',
          icon: DashboardIcon,
          link: '/reports',
          isSuperUserOnly: true,
          permissions: ['*']
        }
      ]
    },
    {
      header: 'Reports',
      permissions: [REPORTS_FEATURES.VIEW_STOCK],
      items: [
        {
          name: 'Stock',
          icon: InventoryIcon,
          link: '/reports/stock',
          isSuperUserOnly: true,
          permissions: [REPORTS_FEATURES.VIEW_STOCK]
        },
        {
          name: 'Stock Limit',
          icon: InventoryIcon,
          link: '/reports/stock-limit',
          isSuperUserOnly: true,
          permissions: [REPORTS_FEATURES.VIEW_STOCK]
        }
      ]
    },
    {
      header: 'Account',
      permissions: ['*'],
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/reports/my-account',
          permissions: ['*']
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout',
          permissions: ['*']
        }
      ]
    }
  ];
};

const getIAMAppMenuItems = () => {
  return [
    {
      header: 'User Management',
      permissions: ['*'],
      items: [
        {
          name: 'Manage Users',
          icon: PersonIcon,
          link: '/iam/manage-users',
          isSuperUserOnly: true,
          permissions: ['*']
        }
      ]
    },
    {
      header: 'Account',
      permissions: ['*'],
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/iam/my-account',
          permissions: ['*']
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout',
          permissions: ['*']
        }
      ]
    }
  ];
};

const getDiscountAppMenuItems = () => {
  return [
    {
      header: 'Home',
      permissions: ['*'],
      items: [
        {
          name: 'Discount',
          icon: PersonIcon,
          link: '/discount/home',
          permissions: ['*']
        }
      ]
    },
    {
      header: 'Account',
      permissions: ['*'],
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/discount/my-account',
          permissions: ['*']
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout',
          permissions: ['*']
        }
      ]
    }
  ];
};

const getOrdersAppMenuItems = () => {
  return [
    {
      header: '',
      permissions: ['*'],
      items: [
        {
          name: 'Home',
          icon: PersonIcon,
          link: '/orders',
          permissions: ['*']
        }
      ]
    },
    {
      header: 'Orders',
      permissions: [ORDERS_FEATURES.VIEW_PLAN, ORDERS_FEATURES.VIEW_ORDERS],
      items: [
        {
          name: 'Planning',
          icon: PersonIcon,
          link: '/orders/planning',
          permissions: [ORDERS_FEATURES.VIEW_PLAN]
        },
        {
          name: 'Booking',
          icon: PersonIcon,
          link: '/orders/booking',
          permissions: [ORDERS_FEATURES.VIEW_PLAN, ORDERS_FEATURES.VIEW_ORDERS],
          criteria: 'AND'
        },
        {
          name: 'Direct Booking',
          icon: PersonIcon,
          link: '/orders/direct-booking',
          permissions: [ORDERS_FEATURES.VIEW_ORDERS]
        }
      ]
    },
    {
      header: 'Entities',
      permissions: [ORDERS_FEATURES.CREATE_ENTITY],
      items: [
        {
          name: 'Create New',
          icon: PersonIcon,
          link: '/orders/new-entities',
          permissions: [ORDERS_FEATURES.CREATE_ENTITY]
        }
      ]
    },
    {
      header: 'Reports',
      permissions: [ORDERS_FEATURES.ANALYSIS],
      items: [
        {
          name: 'Analysis',
          icon: PersonIcon,
          link: '/orders/order-analysis',
          permissions: [ORDERS_FEATURES.ANALYSIS]
        },
        {
          name: 'Order Report',
          icon: PersonIcon,
          link: '/orders/order-report',
          permissions: [ORDERS_FEATURES.ANALYSIS]
        }
      ]
    },
    {
      header: 'Account',
      permissions: ['*'],
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/orders/my-account',
          permissions: ['*']
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout',
          permissions: ['*']
        }
      ]
    }
  ];
};

const getDefaultAppMenuItems = () => {
  return [
    {
      header: 'Account',
      permissions: ['*'],
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/home/my-account',
          permissions: ['*']
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout',
          permissions: ['*']
        }
      ]
    }
  ];
};

class SideBarMenuItems {
  static getMenuItems(appName) {
    switch (appName.toLowerCase()) {
    case 'reports': return getReportsAppMenuItems();
    case 'iam': return getIAMAppMenuItems();
    case 'discount': return getDiscountAppMenuItems();
    case 'orders': return getOrdersAppMenuItems();
    default: return getDefaultAppMenuItems();
    }
  }
}

export { SideBarMenuItems };
