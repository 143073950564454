import { Constants } from './Constants';

const TREND_TYPE = {
  1: 'Today',
  2: 'Yesterday',
  3: 'This Month',
  4: 'Last Month',
  5: 'This Year',
  6: 'Last Year',
  7: 'Custom'
};

const APP_INFO = {
  REPORTS: Constants.REPORTS_APP_KEY,
  RAJ_IAM: Constants.IAM_APP_KEY,
  DISCOUNT: Constants.DISCOUNT_APP_KEY,
  ORDERS: Constants.ORDERS_APP_KEY,
  APPROVALS: Constants.APPROVALS_APP_KEY
};

const MATERIAL_TYPE = {
  POLYESTER: 1,
  SILK: 2,
  READYMADE: 3
};

const ENTITY_TYPE = {
  1: 'Category',
  2: 'Product',
  3: 'Model',
  4: 'Brand',
  5: 'Supplier'
};

const ORDER_REPORT_OPTIONS = {
  1: 'Planned Order',
  2: 'Direct Order'
};

const ANALYSIS_COMPONENTS = {
  active_plans: 1,
  plan_details: 2,
  plan_orders: 3,
  orders_by_product: 4,
  orders_by_serial: 5,
  order_details: 6
};

const ATTACHMENT_STATUS = {
  NEW: 'New',
  NOT_DOWNLOADED: 'Not Downloaded',
  UPLOADING: 'Uploading',
  DOWNLOADING: 'Downloading',
  DONE: 'Done'
};
export {
  TREND_TYPE, APP_INFO, MATERIAL_TYPE, ENTITY_TYPE, ORDER_REPORT_OPTIONS, ANALYSIS_COMPONENTS, ATTACHMENT_STATUS
};
