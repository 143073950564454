import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Container, CircularProgress, Grid, TextField, Card,
  Table, TableHead, TableContainer, Paper, TableCell, TableBody,
  TableRow, IconButton, Typography, Tooltip } from '@mui/material';
import { Utils } from '../../../../utils/UtilFunctions';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { ANALYSIS_COMPONENTS, MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GetOrderDetails = ({ orderId, onSubmit, onBack }) => {
  const [orderDetails, setOrderDetails] = useState({
    plan_id: '',
    order_date: '',
    ac_name: '',
    material_type: '',
    order_data: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    GET_ORDERS_FOR_ANALYSIS: 'CGOD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(orderId)) {
      raiseGetOrderRequest(orderId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getOrderDetailsForAnalysis && apiToken == APIToken.GET_ORDERS_FOR_ANALYSIS ) {
      if (response.data != undefined) {
        populatePlanData(response.data);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getOrderDetailsForAnalysis && apiToken == APIToken.GET_ORDERS_FOR_ANALYSIS) {
      defaultMsg = 'Failed to retrieve Order Details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetOrderRequest = (orderId) => {
    setIsLoading(true);
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getOrderDetailsForAnalysis, params: params },
      APIToken.GET_ORDERS_FOR_ANALYSIS, processSuccess, processError);
  };

  const populatePlanData = (data) => {
    const obj = {};
    data.order_data.forEach((plan) => {
      const materialType = MATERIAL_TYPE[plan.material_type];
      if (materialType == 3) {
        const key = `${plan.item_id}_${plan.cate_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: plan.cate_name,
            cate_id: plan.cate_id,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: plan.model_name,
          brand_name: plan.brand_name,
          model_id: plan.model_id,
          brand_id: plan.brand_id,
          size_id: plan.size_id,
          planned_quantity: plan.planned_quantity,
          quantity: plan.quantity,
          plan_serial: plan.plan_serial,
          description: plan.description
        });
      } else if (materialType == 2) {
        const key = `${plan.item_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: plan.max_price,
          min_price: plan.min_price,
          quantity: plan.quantity,
          plan_serial: plan.plan_serial,
          buy_price: plan.buy_price,
          sell_price: plan.sell_price,
          description: plan.description,
          planned_quantity: plan.planned_quantity
        });
      } else {
        const key = `${plan.item_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: plan.max_price,
          min_price: plan.min_price,
          quantity: plan.quantity,
          plan_serial: plan.plan_serial,
          buy_price: plan.buy_price,
          description: plan.description,
          vrty_id: plan.vrty_id,
          vrty_name: plan.vrty_name,
          planned_quantity: plan.planned_quantity
        });
      }
    });
    const filteredData = Object.values(obj);
    setOrderDetails({
      ...orderDetails,
      plan_id: data.plan_id,
      order_date: data.order_date,
      ac_name: validateStringForNull(data.ac_name) ? data.ac_name: '',
      order_data: filteredData
    });
  };

  const handlePlanIdClick = () => {
    const obj={
      component_id: ANALYSIS_COMPONENTS.plan_details,
      plan_id: orderDetails.plan_id
    };
    onSubmit(obj);
  };

  const handleOrdersByProductClick = (prodId, materialType) => {
    const obj={
      component_id: ANALYSIS_COMPONENTS.orders_by_product,
      plan_id: orderDetails.plan_id,
      prod_id: prodId,
      material_type: materialType
    };
    onSubmit(obj);
  };

  const handleOrdersBySerialClick = (serial) => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.orders_by_serial,
      plan_id: orderDetails.plan_id,
      plan_serial: serial
    };
    onSubmit(obj);
  };

  const getMaterialContent = (order) => {
    switch (order.material_type) {
    case 1:
    case 2:
      return getSilkPolyesterContent(order);
    case 3:
      return getReadyMadeContent(order);
    default:
      return 'Invalid Material type';
    }
  };

  const getReadyMadeContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={5} sm={5}>
                <TextField
                  id="category Name"
                  label="Category Name"
                  name="cate_name"
                  autoComplete ='off'
                  variant='standard'
                  value={order.cate_name}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} sm={5}>
                <TextField
                  id="product Name"
                  label="Product Name"
                  name="prod_name"
                  autoComplete ='off'
                  variant='standard'
                  value={order.prod_name}
                  size='small'
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sm={2} display='flex' justifyContent='flex-end'>
                <Tooltip title='Order By Product'>
                  <IconButton
                    color='primary'
                    onClick={() => handleOrdersByProductClick(order.prod_id, order.material_type)}
                    sx={{ p: 1 }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Model</TableCell>
                    <TableCell>Brand</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Planned Qty</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.sub_data.map((planSubData, j) => (
                    <TableRow key={planSubData.id}>
                      <TableCell>{planSubData.model_name}</TableCell>
                      <TableCell>{planSubData.brand_name}</TableCell>
                      <TableCell>{planSubData.description}</TableCell>
                      <TableCell>{planSubData.size_id}</TableCell>
                      <TableCell>{planSubData.planned_quantity}</TableCell>
                      <TableCell>
                        <Tooltip title='Orders By Range'>
                          <Typography variant='h6' color='red'
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                          >
                            {planSubData.quantity}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getSilkPolyesterContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={5} sm={5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  value={order.prod_id}
                  size='small'
                  fullWidth
                  variant='standard'
                  disabled={true}
                />
              </Grid>
              <Grid item xs={5} sm={5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  variant='standard'
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2} sm={2} display='flex' justifyContent='flex-end'>
                <Tooltip title='Order By Product'>
                  <IconButton
                    color='primary'
                    onClick={() => handleOrdersByProductClick(order.prod_id, order.material_type)}
                    sx={{ p: 1 }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {order.material_type == 1 ? (
                      <React.Fragment>
                        <TableCell>Variety</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Buy Price</TableCell>
                        <TableCell>Planned Qty</TableCell>
                        <TableCell>Quantity</TableCell>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TableCell>Description</TableCell>
                        <TableCell>Buy Price</TableCell>
                        <TableCell>Sell Price</TableCell>
                        <TableCell>Planned Qty</TableCell>
                        <TableCell>Quantity</TableCell>
                      </React.Fragment>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.sub_data.map((planSubData, j) => (
                    order.material_type == 1 ? (
                      <TableRow key={planSubData.id}>
                        <TableCell>{planSubData.vrty_name}</TableCell>
                        <TableCell>{planSubData.description}</TableCell>
                        <TableCell>{planSubData.buy_price}</TableCell>
                        <TableCell>{planSubData.planned_quantity}</TableCell>
                        <TableCell>
                          <Tooltip title='Orders By Range'>
                            <Typography variant='h6' color='red'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                            >
                              {planSubData.quantity}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={planSubData.id}>
                        <TableCell>{planSubData.description}</TableCell>
                        <TableCell>{planSubData.buy_price}</TableCell>
                        <TableCell>{planSubData.sell_price}</TableCell>
                        <TableCell>{planSubData.planned_quantity}</TableCell>
                        <TableCell>
                          <Tooltip title = 'Orders by Range'>
                            <Typography variant='h6' color='red'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                            >
                              {planSubData.quantity}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={1}>
          <Tooltip title='Back'>
            <IconButton
              color='primary'
              onClick={onBack}
              sx={{ p: 1 }}
            >
              <ArrowBackIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={11} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Analysis - Order Details
          </Typography>
        </Grid>
        <Grid item xs={10} sm={4}>
          <TextField
            id="Plan Id"
            label="Plan Id"
            name="plan_id"
            autoComplete ='off'
            value={orderDetails.plan_id}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <Tooltip title='View Plan'>
            <IconButton
              color='primary'
              onClick={handlePlanIdClick}
              sx={{ p: 1 }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="order Id"
            label="Order Id"
            name="order_id"
            autoComplete ='off'
            value={orderId}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id="Order Date"
            label="Order Date"
            name="order_date"
            autoComplete ='off'
            value={orderDetails.order_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            id="ac_name"
            label="Supplier Name"
            name="ac_name"
            autoComplete ='off'
            value={orderDetails.ac_name}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        {orderDetails.order_data.length > 0 ?
          orderDetails.order_data.map((order, index) => (
            <React.Fragment key={index}>
              {getMaterialContent(order)}
            </React.Fragment>
          )) : '' }
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

GetOrderDetails.propTypes = {
  orderId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default GetOrderDetails;
