const DISCOUNT_FEATURES = {
  VIEW_DISCOUNT: 'VIEW_DISCOUNT',
  CREATE_OR_UPDATE_DISCOUNT: 'CREATE_OR_UPDATE_DISCOUNT',
  REMOVE_DISCOUNT: 'REMOVE_DISCOUNT'
};

const ORDERS_FEATURES = {
  VIEW_PLAN: 'VIEW_PLAN',
  CREATE_OR_UPDATE_PLAN: 'CREATE_OR_UPDATE_PLAN',
  VIEW_ORDERS: 'VIEW_ORDERS',
  CREATE_OR_UPDATE_ORDERS: 'CREATE_OR_UPDATE_ORDERS',
  CREATE_ENTITY: 'CREATE_ENTITY',
  ANALYSIS: 'ANALYSIS'
};

const APPROVALS_FEATURES = {
  LIST_APPROVALS: 'LIST_APPROVALS',
  GRANT_REVOKE_APPROVALS: 'GRANT_REVOKE_APPROVALS'
};

const REPORTS_FEATURES = {
  DASHBOARD: 'DASHBOARD',
  VIEW_STOCK: 'VIEW_STOCK',
  MANAGE_STOCK: 'MANAGE_STOCK'
};

const validateFeature = (featureList, featureId) => {
  const arr = featureList.filter((feature) => feature.feature_id == featureId);
  if (arr == undefined || arr.length != 1) {
    return false;
  }
  return arr[0].is_enabled;
};

export {
  DISCOUNT_FEATURES, validateFeature, ORDERS_FEATURES, APPROVALS_FEATURES, REPORTS_FEATURES
};
