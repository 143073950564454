import { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField
} from '@mui/material';
import { validateNumber, validateStringForNull } from '../../../utils/FieldValidations';
import { CookieUtils, Utils } from '../../../utils/UtilFunctions';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import React from 'react';
import APIData from '../../../utils/APIData';
import Services from '../../../utils/Services';

const AccountDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    user_id: '',
    user_name: '',
    phone: ''
  });
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const [originalValues, setOriginalValues] = useState(values);
  const APIToken = {
    GET_USER: 'CAD01',
    MODIFY_USER: 'CAD02'
  };
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      setValues({ ...values,
        user_id: response.data.user_id,
        user_name: response.data.user_name,
        phone: response.data.phone ?? ''
      });
    } else if (apiData == APIData.modifyUser && apiToken == APIToken.MODIFY_USER) {
      showSnackBar('success', response.message ?? 'User Details Modified successfully');
      setOriginalValues(values);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg = 'Failed to Get user details';
    } else if (apiData == APIData.modifyUser && apiToken == APIToken.MODIFY_USER) {
      defaultMsg = 'Failed to Modify user details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const handleChange = (event) => {
    switch (event.target.name) {
    default:
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
      break;
    }
  };

  const getValuesToModify = () => {
    const result = {};
    Object.keys(values).forEach((key) => {
      if (originalValues[key] !== values[key]) {
        if (key == 'user_name' && !validateStringForNull(values[key])) {
          throw new Error('Invalid Name');
        }
        if (key == 'phone' && !validateNumber(values[key])) {
          throw new Error('Invalid Phone');
        }
        result[key] = values[key];
      }
    });
    if (Object.keys(result).length == 0) {
      throw new Error('Nothing to update');
    }
    return result;
  };

  const raiseModifyRequest = (modifiedValues) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.modifyUser, uriValues: [values.user_id], params: modifiedValues },
      APIToken.MODIFY_USER, processSuccess, processError);
  };

  const onSave = () => {
    try {
      const modifiedValues = getValuesToModify();
      raiseModifyRequest(modifiedValues);
    } catch (err) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message
      });
      setIsLoading(false);
    }
  };

  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  return (
    <React.Fragment>
      <form
        autoComplete="off"
        noValidate
      >
        <Card>
          <CardHeader
            title="Edit Profile"
          />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  name="user_name"
                  size='small'
                  label="User Name"
                  required
                  onChange={handleChange}
                  value={values.user_name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  name="phone"
                  size='small'
                  label="Phone"
                  required
                  onChange={handleChange}
                  value={values.phone}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size='small'
              onClick={() => {onSave();}}
            >
              Update
            </Button>
          </Box>
        </Card>
      </form>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default AccountDetails;
