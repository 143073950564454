import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  TextField,
  CircularProgress,
  Grid
} from '@mui/material';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import { CookieUtils, KeyPressUtil, Utils } from '../../../utils/UtilFunctions';
import CustomAutocomplete from '../../../common/components/CustomAutocomplete';
import { Constants } from '../../../utils/Constants';
import APIData from '../../../utils/APIData';
import Services from '../../..//utils/Services';
import Logo from '../../../common/components/Logo';

const Login = () => {
  const pageTitle = 'Sign In | Rajmahal Apps';
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const [userId, setUserId] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const passwordInputRef = useRef();
  const [isFocusSetIntentional, setIsFocusSetIntentional] = useState(false);
  const APIToken = {
    GET_USER: 'PL01',
    LOGIN: 'PL02'
  };

  const enterFunction = useCallback((e) => KeyPressUtil.enterFunction(e, onEnterPressed), [userId, password]);
  useEffect(() => KeyPressUtil.keyListenerFunc(enterFunction), [enterFunction]);

  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const onEnterPressed = () => {
    if (!isFocusSetIntentional) {
      if (passwordInputRef.current.children.length == 2) {
        const node = passwordInputRef.current.children[1];
        if (node.children.length > 0 && node.children[0] == document.activeElement) {
          handleSubmit();
        }
      }
    }
    setIsFocusSetIntentional(false);
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      const obj = {
        user_id: response.data.user_id,
        user_name: response.data.user_name,
        role: response.data.role,
        loginHash: callbackValues.values.login_hash,
        allowed_apps: response.data.app_list
      };
      CookieUtils.setCookie(Constants.LOGIN_HASH, JSON.stringify(obj));
      window.location.reload();
      setIsLoading(false);
    } else if (apiData == APIData.login && apiToken == APIToken.LOGIN) {
      const obj = {
        loginHash: response.data.login_hash
      };
      CookieUtils.setCookie(Constants.LOGIN_HASH, JSON.stringify(obj));
      tryGetUser(response.data);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg = 'Failed to get user details';
    } else if (apiData == APIData.login && apiToken == APIToken.LOGIN) {
      defaultMsg = 'Invalid UserId/Password';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const tryGetUser = (values) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [values.emp_id] },
      APIToken.GET_USER, processSuccess, processError, { values: values });
  };

  const tryLogin = (values) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.login, params: values },
      APIToken.LOGIN, processSuccess, processError );
  };

  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const handleSubmit = () => {
    let errMsg = '';
    if (userId == undefined || userId === '') {
      errMsg = 'Invalid User Name';
    } else if (password == undefined || password === '') {
      errMsg = 'Invalid Password';
    }

    if (errMsg !== '') {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: errMsg
      });
      return;
    }
    setIsLoading(true);
    const values = {
      user_id: userId,
      password: password
    };
    tryLogin(values);
  };

  const handleAutocompleteChange = (value) => {
    if (value !== undefined && value !== null) {
      setUserId(value.user_id);
      setIsFocusSetIntentional(true);
      const node = passwordInputRef.current.children[1];
      node.children[0].focus();
    } else {
      setUserId(undefined);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '98vh',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="xs">
          <Grid container gap={2}>
            <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
              <Logo
                alt="Rajmahal"
                src="/static/images/Rajmahal_2.png"
                height={100}/>
            </Grid>
            <Grid item xs={12}>
              <CustomAutocomplete
                autoFocus
                label={'User Name'}
                apiInfo={{
                  searchURI: APIData.searchUser,
                  searchField: 'user_name'
                }}
                suppressUpperCase = {true}
                onSelectionChange={(event, value) => handleAutocompleteChange(value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                autoComplete='off'
                label="Password"
                size='small'
                onChange={(event) => {setPassword(event.target.value);}}
                type="password"
                variant="outlined"
                ref={passwordInputRef}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                disabled={isLoading}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                {isLoading ? <CircularProgress /> : 'Sign In'}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {getSnackbar}
    </React.Fragment>
  );
};

export default Login;
