import { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Utils } from '../../../utils/UtilFunctions';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import React from 'react';
import APIData from '../../../utils/APIData';
import Services from '../../../utils/Services';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { green, red } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { cloneDeep } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { validateNumber, validateStringForNull } from '../../../utils/FieldValidations';
import KeyIcon from '@mui/icons-material/Key';
import SettingsIcon from '@mui/icons-material/Settings';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import { Stack } from '@mui/system';

const GrantAppPermisssion = ({ userId, onSuccessfulCommit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };

  const [expandedAppId, setExpandedAppId] = useState(undefined);
  const [selectedAppId, setSelectedAppId] = useState(undefined);
  const [selectedSettingsAppId, setSelectedSettingsAppId] = useState(undefined);

  const [allApps, setAllApps] = useState([]);
  const [appRoles, setAppRoles] = useState({});
  const [allShops, setAllShops] = useState([]);
  const [appSettings, setAppSettings] = useState({
    DISCOUNT: undefined,
    REPORTS: undefined
  });

  const [originalAppSettings, setOriginalAppSettings] = useState({
    DISCOUNT: undefined,
    REPORTS: undefined
  });

  const [userInfo, setUserInfo] = useState({
    user_id: '',
    user_name: '',
    phone: '',
    is_active: false
  });
  const [originalUserInfo, setOriginalUserInfo] = useState(userInfo);
  const [appPermissions, setAppPermissions] = useState({});
  const [originalAppPermissions, setOriginalAppPermissions] = useState({});

  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(false);
  const [isRemoveAppDialogOpen, setIsRemoveAppDialogOpen] = useState(false);
  const [selectedAppForDelete, setSelectedAppForDelete] = useState(undefined);

  const APIToken = {
    GET_USER: 'CGAP01',
    LIST_SHOPS: 'CGAP02',
    GET_DISCOUNT_SETTINGS: 'CGAP03',
    RESET_PASSWORD: 'CGAP04',
    GET_APP_ROLES: 'CGAP05',
    LIST_APPS: 'CGAP06',
    GET_APP_FEATURES: 'CGAP07',
    MANAGE_PERMISSION: 'CGAP08',
    UPDATE_DISCOUNT_SETTINGS: 'CGAP09',
    DELETE_USER_APP: 'CGAP10',
    MODIFY_USER: 'CGAP11',
    GET_REPORT_SETTINGS: 'CGAP12',
    UPDATE_REPORT_SETTINGS: 'CGAP13'
  };

  useEffect(() => {
    raiseGetUserRequest();
    raiseGetAppsRequest();
    raiseGetShopsRequest();
  }, []);

  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      const obj = {
        user_id: response.data.user_id,
        user_name: response.data.user_name,
        phone: response.data.phone,
        is_active: response.data.is_active
      };
      setUserInfo(obj);
      setOriginalUserInfo(obj);
      updateValuesForModify(callbackValues.appId, response.data);
    } else if (apiData == APIData.listShops && apiToken == APIToken.LIST_SHOPS) {
      setAllShops(response.data);
    } else if ((apiData == APIData.getDiscountSettings && apiToken == APIToken.GET_DISCOUNT_SETTINGS) ||
      (apiData == APIData.getReportSettings && apiToken == APIToken.GET_REPORT_SETTINGS)) {
      setAppSettings({ ...appSettings, [callbackValues.appId]: response.data });
      setOriginalAppSettings({ ...originalAppSettings, [callbackValues.appId]: response.data });
    } else if (apiData == APIData.resetPassword && apiToken == APIToken.RESET_PASSWORD) {
      showSnackBar('success', response.message ?? 'Password reset successfully');
    } else if (apiData == APIData.getAppRoles && apiToken == APIToken.GET_APP_ROLES) {
      setAppRoles({ ...appRoles, [callbackValues.appId]: response.data });
    } else if (apiData == APIData.listApps && apiToken == APIToken.LIST_APPS) {
      setAllApps(response.data);
    } else if (apiData == APIData.getAppFeatures && apiToken == APIToken.GET_APP_FEATURES) {
      updateAppFeatures(callbackValues.appId, response.data);
    } else if (apiData == APIData.managePermission && apiToken == APIToken.MANAGE_PERMISSION) {
      showSnackBar('success', response.message);
      setSelectedAppId(undefined);
      raiseGetUserRequest(callbackValues.appId);
    } else if ((apiData == APIData.updateDiscountSettings && apiToken == APIToken.UPDATE_DISCOUNT_SETTINGS) ||
      apiData == APIData.updateReportSettings && apiToken == APIToken.UPDATE_REPORT_SETTINGS) {
      showSnackBar('success', response.message);
      setSelectedSettingsAppId(undefined);
      setOriginalAppSettings(cloneDeep(appSettings));
    } else if (apiData == APIData.deleteUserApp && apiToken == APIToken.DELETE_USER_APP) {
      showSnackBar('success', response.message);
      const valueObj = cloneDeep(appPermissions);
      delete valueObj[selectedAppForDelete];
      setAppPermissions(valueObj);

      const original = cloneDeep(originalAppPermissions);
      delete original[selectedAppForDelete];
      setOriginalAppPermissions(original);
      setSelectedAppForDelete(undefined);

      raiseGetUserRequest(selectedAppForDelete);
    } else if (apiData == APIData.modifyUser && apiToken == APIToken.MODIFY_USER) {
      showSnackBar('success', response.message);
      setOriginalUserInfo(userInfo);
      if (onSuccessfulCommit != undefined) {
        onSuccessfulCommit();
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg='Failed to get user';
    } else if (apiData == APIData.listShops && apiToken == APIToken.LIST_SHOPS) {
      defaultMsg = 'Failed to get Shop List';
    } else if (apiData == APIData.getDiscountSettings && apiToken == APIToken.GET_DISCOUNT_SETTINGS) {
      defaultMsg = 'Failed to get discount settings';
    } else if (apiData == APIData.resetPassword && apiToken == APIToken.RESET_PASSWORD) {
      defaultMsg = 'Failed to reset password';
    } else if (apiData == APIData.getAppRoles && apiToken == APIToken.GET_APP_ROLES) {
      defaultMsg = 'Failed to get App roles';
    } else if (apiData == APIData.listApps && apiToken == APIToken.LIST_APPS) {
      defaultMsg = 'Failed to list Apps';
    } else if (apiData == APIData.getAppFeatures && apiToken == APIToken.GET_APP_FEATURES) {
      defaultMsg = 'Failed to get app features';
    } else if (apiData == APIData.managePermission && apiToken == APIToken.MANAGE_PERMISSION) {
      defaultMsg = 'Failed to manage permisson';
    } else if (apiData == APIData.updateDiscountSettings && apiToken == APIToken.UPDATE_DISCOUNT_SETTINGS) {
      defaultMsg = 'Failed to update discount settings';
    } else if (apiData == APIData.deleteUserApp && apiToken == APIToken.DELETE_USER_APP) {
      defaultMsg='Failed to delete user App';
    } else if (apiData == APIData.modifyUser && apiToken == APIToken.MODIFY_USER) {
      defaultMsg = 'Failed to Update User Details';
    } else if (apiData == APIData.getReportSettings && apiToken == APIToken.GET_REPORT_SETTINGS) {
      defaultMsg = 'Failed to get report settings';
    } else if (apiData == APIData.updateDiscountSettings && apiToken == APIToken.UPDATE_DISCOUNT_SETTINGS) {
      defaultMsg = 'Failed to update report settings';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = (appId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [userId] },
      APIToken.GET_USER, processSuccess, processError, { appId: appId });
  };

  const raiseGetShopsRequest = () => {
    setIsLoading(true);
    const params = 'include_all=true';
    Services.sendBackendRequest({ apiData: APIData.listShops, params: params },
      APIToken.LIST_SHOPS, processSuccess, processError);
  };

  const raiseGetDiscountSettingsRequest = (appId) => {
    setIsLoading(true);
    const params = 'user_id=' + userId;
    Services.sendBackendRequest({ apiData: APIData.getDiscountSettings, params: params },
      APIToken.GET_DISCOUNT_SETTINGS, processSuccess, processError, { appId: appId });
  };

  const raiseGetReportSettingsRequest = (appId) => {
    setIsLoading(true);
    const params = 'user_id=' + userId;
    Services.sendBackendRequest({ apiData: APIData.getReportSettings, params: params },
      APIToken.GET_REPORT_SETTINGS, processSuccess, processError, { appId: appId });
  };

  const raiseResetPasswordRequest = () => {
    setIsLoading(true);
    setIsResetPasswordDialogOpen(false);
    Services.sendBackendRequest({ apiData: APIData.resetPassword, uriValues: [userId] },
      APIToken.RESET_PASSWORD, processSuccess, processError );
  };

  const raiseGetAppRolesRequest = (appId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getAppRoles, uriValues: [appId] },
      APIToken.GET_APP_ROLES, processSuccess, processError, { appId: appId });
  };

  const raiseGetAppsRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.listApps },
      APIToken.LIST_APPS, processSuccess, processError);
  };

  const raiseGetAppFeaturesRequest = (appId, role) => {
    if (role == -1) {
      return;
    }
    setIsLoading(true);
    const params = 'role=' + role;
    Services.sendBackendRequest({ apiData: APIData.getAppFeatures, uriValues: [userId, appId], params: params },
      APIToken.GET_APP_FEATURES, processSuccess, processError, { appId: appId });
  };

  const raiseManagePermissionRequest = (appId, params) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.managePermission, uriValues: [userId, appId], params: params },
      APIToken.MANAGE_PERMISSION, processSuccess, processError, { appId: appId });
  };

  const raiseUpdateDiscountSettingsRequest = (appId, params) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.updateDiscountSettings, params: params },
      APIToken.UPDATE_DISCOUNT_SETTINGS, processSuccess, processError);
  };

  const raiseUpdateReportSettingsRequest = (appId, params) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.updateReportSettings, params: params },
      APIToken.UPDATE_REPORT_SETTINGS, processSuccess, processError);
  };

  const raiseDeletePermissionRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.deleteUserApp, uriValues: [userId, selectedAppForDelete] },
      APIToken.DELETE_USER_APP, processSuccess, processError);
  };

  const updateValuesForModify = (appId, data) => {
    const obj = {};
    if (data.app_list != undefined) {
      data.app_list.map((app) => {
        obj[app.app_id] = {
          appId: app.app_id,
          appName: app.app_name,
          roleId: app.role,
          roleName: app.role_name,
          features: app.available_features,
          shops: app.allowed_shops ?? []
        };
      });
      if (appId != undefined) {
        if (obj[appId] != undefined) {
          setAppPermissions({ ...appPermissions, [appId]: obj[appId] });
          setOriginalAppPermissions({ ...originalAppPermissions, [appId]: obj[appId] });
        }
      } else {
        setAppPermissions(obj);
        setOriginalAppPermissions(obj);
      }
    }
  };

  const updateAppFeatures = (appId, newFeatures) => {
    setAppPermissions((data) => {
      return {
        ...data,
        [appId]: {
          ...data[appId],
          features: newFeatures
        }
      };
    });
    setOriginalAppPermissions((data) => {
      return {
        ...data,
        [appId]: {
          ...data[appId],
          features: newFeatures
        }
      };
    });
  };

  const handleCustomRoleCheckBoxChange = (appId, featureId, event) => {
    const features = appPermissions[appId].features;
    const index = features.findIndex((feature) => feature.feature_id == featureId);
    features[index].is_enabled = event.target.checked;

    for (let i = index + 1; i < features.length; i++) {
      if (features[index].feature_group == features[i].feature_group) {
        features[i].is_enabled = event.target.checked;
      }
    }
    setAppPermissions((data) => {
      return {
        ...data,
        [appId]: {
          ...data[appId],
          features: features
        }
      };
    });
  };

  const handleShopCheckBoxChange = (appId, shopId, event) => {
    let shops = cloneDeep(appPermissions[appId].shops ?? []);
    if (event.target.checked) {
      const newObj = {
        shop_id: shopId
      };
      shops.push(newObj);
    } else {
      shops = shops.filter((shop) => shop.shop_id !== shopId);
    }

    setAppPermissions((data) => {
      return {
        ...data,
        [appId]: {
          ...data[appId],
          shops: shops,
          isModified: true
        }
      };
    });
  };

  const handleResetPassswordClick = () => {
    if (validateStringForNull(userId)) {
      raiseResetPasswordRequest();
    }
  };

  const getUserInfoTitle = () => {
    return (
      <>
        <Typography variant='h5'>
          Edit User Details
        </Typography>
        <Typography variant='h6'>
          (User Id: {userInfo.user_id})
        </Typography>
      </>
    );
  };

  const handleUserInfoChange = (event) => {
    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.value
    });
  };

  const getValuesToModify = () => {
    const result = {};
    Object.keys(userInfo).forEach((key) => {
      if (originalUserInfo[key] !== userInfo[key]) {
        if (key == 'user_name' && !validateStringForNull(userInfo[key])) {
          throw new Error('Invalid Name');
        }
        if (key == 'phone' && !validateNumber(userInfo[key])) {
          throw new Error('Invalid Phone');
        }
        result[key] = userInfo[key];
      }
    });
    if (Object.keys(result).length == 0) {
      throw new Error('Nothing to update');
    }
    return result;
  };

  const raiseModifyRequest = (modifiedValues) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.modifyUser, uriValues: [userId], params: modifiedValues },
      APIToken.MODIFY_USER, processSuccess, processError);
  };

  const handleUserInfoUpdateClick = () => {
    try {
      const modifiedValues = getValuesToModify();
      raiseModifyRequest(modifiedValues);
    } catch (err) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message
      });
      setIsLoading(false);
    }
  };

  const getUserDetails = () => {
    return (
      <Card sx={{ m: 2 }}>
        <CardHeader title={getUserInfoTitle()}
          action={
            <React.Fragment>
              <IconButton sx={{ color: userInfo.is_active ? green[300] : red[300] }}>
                <FiberManualRecordIcon sx={{ fontSize: '20px' }} />
                <Typography variant='h6'>
                  {userInfo.is_active ? 'Active' : 'Inactive'}
                </Typography>
              </IconButton>
            </React.Fragment>
          }
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="user_name"
                size='small'
                label="User Name"
                required
                onChange={handleUserInfoChange}
                value={userInfo.user_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="phone"
                size='small'
                label="Phone"
                required
                onChange={handleUserInfoChange}
                value={userInfo.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
              {userInfo.is_active ? (
                <Button
                  color="primary"
                  startIcon={<KeyIcon />}
                  variant="outlined"
                  size='small'
                  onClick={() => setIsResetPasswordDialogOpen(true)}
                >
                  Reset password
                </Button>
              ) : ''}
              <Button
                color="primary"
                variant="contained"
                size='small'
                sx={{ ml: 1 }}
                onClick={() => handleUserInfoUpdateClick()}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const getPermissionCards = () => {
    if (appPermissions != undefined && Object.keys(appPermissions).length > 0) {
      return Object.keys(appPermissions).map((appId) => {
        if (selectedAppId == appId) {
          return getEditAppCard(appPermissions[appId]);
        } else if (selectedSettingsAppId == appId) {
          return getSettingsAppCard(appPermissions[appId]);
        } else {
          return getViewAppCard(appPermissions[appId]);
        }
      });
    }
    return '';
  };

  const getViewCardTitle = (app) => {
    return (
      <Grid container alignItems={'center'}>
        <Grid item>{app.appName}</Grid>
        {app.roleName != undefined ? (
          <Grid item sx={{ backgroundColor: 'green', p: 0.5, px: 1, ml: 3, borderRadius: '25px' }}>
            <Typography variant='h6'>
              {app.roleName}
            </Typography>
          </Grid>
        ) : ''}
        {app.isNew == true || app.isModified == true ? (
          <Grid item sx={{ ml: 3 }}>
            <Grid item sx={{ px: 1, ml: 1 }}>
              <Typography variant='h6' sx={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                Unsaved
              </Typography>
            </Grid>
          </Grid>
        ) : ''}
      </Grid>
    );
  };

  const getRoleList =(appId) => {
    const choiceArray = [];
    appRoles[appId].map((role) => {
      choiceArray.push(<MenuItem value={role.role} name={role.role_name} key={role.role}>{role.role_name}</MenuItem>);
    });
    return choiceArray;
  };

  const handleRoleChoiceChange = (app, event) => {
    const roleObj = appRoles[app.appId].filter((obj) => obj.role == event.target.value)[0];
    const value = cloneDeep(appPermissions);
    value[app.appId].roleId = roleObj.role;
    value[app.appId].roleName = roleObj.role_name;
    value[app.appId].isModified = true;
    setAppPermissions(value);
    raiseGetAppFeaturesRequest(app.appId, event.target.value);
  };

  const handleAppChoiceChange = (event) => {
    const values = cloneDeep(appPermissions);
    let key = undefined;
    Object.keys(values).map((value) => {
      if (values[value].isNew == true) {
        key = value;
      }
    });
    delete values[key];
    const filteredApp = allApps.filter((app) => app.app_id == event.target.value)[0];
    const obj = {
      appId: event.target.value,
      appName: filteredApp.app_name,
      roleId: -1,
      roleName: '',
      features: [],
      shops: [],
      isNew: true
    };

    values[event.target.value] = obj;
    setAppPermissions(values);
    setExpandedAppId(undefined);
    setSelectedAppId(event.target.value);
    raiseGetAppRolesRequest(event.target.value);
  };

  const getRemainingAppList = (appId) => {
    const choiceArray = [];
    allApps.map((app) => {
      if (appPermissions[app.app_id] == undefined || app.app_id == appId) {
        choiceArray.push(<MenuItem value={app.app_id} key={app.app_id}>{app.app_name}</MenuItem>);
      }
    });
    return choiceArray;
  };

  const getEditCardTitle = (app) => {
    return (
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item>
          {app.isNew == true ?
            <FormControl sx={{ width: { xs: '100%', md: 'auto', minWidth: '200px' } }}>
              <InputLabel size="small" id="app-select-label">App</InputLabel>
              <Select
                labelId="app-select-label"
                id="app-select"
                label="App"
                name='app'
                value={app.appId}
                onChange={(event) => handleAppChoiceChange(event)}
                size="small"
              >
                {getRemainingAppList(app.appId)}
              </Select>
            </FormControl> : app.appName}
        </Grid>
        <Grid item>
          {appRoles[app.appId] != undefined ?
            <FormControl sx={{ width: { xs: '100%', md: 'auto', minWidth: '200px' } }}>
              <InputLabel size="small" id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                label="Role"
                name='role'
                value={app.roleId}
                onChange={(event) => handleRoleChoiceChange(app, event)}
                size="small"
              >
                {getRoleList(app.appId)}
              </Select>
            </FormControl> : ''}
        </Grid>
        {app.isNew == true || app.isModified == true ? (
          <Grid item sx={{ px: 1, ml: 1 }}>
            <Typography variant='h6' sx={{ fontStyle: 'italic', textDecoration: 'underline' }}>
              Unsaved
            </Typography>
          </Grid>
        ) : ''}
      </Grid>
    );
  };

  const getSettingsCardTitle = (app) => {
    return (
      <Grid container alignItems={'center'}>
        <Grid item>{app.appName} Settings</Grid>
        {appSettings[app.appId] != undefined && appSettings[app.appId].isModified == true ? (
          <Grid item sx={{ ml: 3 }}>
            <Grid item sx={{ px: 1, ml: 1 }}>
              <Typography variant='h6' sx={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                Unsaved
              </Typography>
            </Grid>
          </Grid>
        ) : ''}
      </Grid>
    );
  };

  const getAccessibleFeatures = (appFeatures) => {
    const arr = [];
    appFeatures.map((feature) => {
      const featureGroup = feature.feature_group;
      const filtered = arr.filter((obj) => obj.name == featureGroup);
      if (filtered.length == 0) {
        const obj = {};
        obj.name = featureGroup;
        obj.features = [{
          id: feature.feature_id,
          name: feature.feature,
          is_enabled: feature.is_enabled
        }];
        arr.push(obj);
      } else {
        filtered[0].features.push(
          {
            id: feature.feature_id,
            name: feature.feature,
            is_enabled: feature.is_enabled
          }
        );
      }
    });
    return arr;
  };

  const handleCardExpansion = (appId) => {
    setExpandedAppId(expandedAppId == appId ? undefined : appId);
  };

  const handleEditClick = (appId) => {
    setExpandedAppId(undefined);
    setSelectedAppId(appId);
    if (appRoles[appId] == undefined) {
      raiseGetAppRolesRequest(appId);
    }
    raiseGetAppFeaturesRequest(appId, appPermissions[appId].roleId);
  };

  const handleSettingsClick = (appId) => {
    setExpandedAppId(undefined);
    switch (appId) {
    case 'DISCOUNT':
      raiseGetDiscountSettingsRequest(appId);
      break;
    case 'REPORTS':
      raiseGetReportSettingsRequest(appId);
      break;
    }
    setSelectedSettingsAppId(appId);
  };

  const handleCancelClick = (appId) => {
    setSelectedAppId(undefined);
    if (appPermissions[appId].isNew == true) {
      const temp = cloneDeep(appPermissions);
      delete temp[appId];
      setAppPermissions(temp);
    } else {
      setAppPermissions({ ...appPermissions, [appId]: originalAppPermissions[appId] });
    }
  };

  const handleCancelSettingsClick = (appId) => {
    setSelectedSettingsAppId(undefined);
    setAppSettings({ ...appSettings, [appId]: originalAppSettings[appId] });
  };

  const getModifiedValues = (appId) => {
    const obj = appPermissions[appId];
    const output = {};
    output.role = obj.roleId;
    if (output.role == -1) {
      throw new Error('Select any role to continue');
    }
    if (obj.roleId == 2) {
      const arr = [];
      obj.features.map((feature) => {
        if (feature.is_enabled) {
          arr.push(feature.feature_id);
        }
      });
      output.available_features = arr;
      if (output.available_features.length == 0) {
        throw new Error('Select atleast one feature to continue.');
      }
    }
    const shopArr = [];
    obj.shops.map((shop) => {
      shopArr.push(shop.shop_id);
    });
    output.allowed_shops = shopArr;
    if (output.allowed_shops.length == 0) {
      throw new Error('Select atleast one shop to continue.');
    }
    return output;
  };

  const getModifiedDiscountSettingsValues = (appId) => {
    const arr = appSettings[appId];
    const output = {};
    output.user_id = userId;
    output.product_settings = arr;
    return output;
  };

  const getModifiedReportSettingsValues = (appId) => {
    const settings = appSettings[appId];
    const localArr = [];
    settings.map((shop) => {
      const allowedMarkArr = [];
      shop.allowed_marks.map((mark) => {
        if (mark.is_allowed) {
          allowedMarkArr.push(mark.item_id);
        }
      });
      const shopObj = {};
      shopObj.shop_id = shop.shop_id;
      shopObj.allowed_marks = allowedMarkArr;
      localArr.push(shopObj);
    });

    const output = {};
    output.user_id = userId;
    output.product_settings = localArr;
    return output;
  };

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const handleSaveClick = (appId) => {
    setIsLoading(true);
    try {
      const params = getModifiedValues(appId);
      raiseManagePermissionRequest(appId, params);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to modify permission for this app');
      setIsLoading(false);
    }
  };

  const handleSaveSettingsClick = (appId) => {
    setIsLoading(true);
    try {
      switch (appId) {
      case 'DISCOUNT':
        const paramsDiscount = getModifiedDiscountSettingsValues(appId);
        raiseUpdateDiscountSettingsRequest(appId, paramsDiscount);
        break;
      case 'REPORTS':
        const paramsReports = getModifiedReportSettingsValues(appId);
        raiseUpdateReportSettingsRequest(appId, paramsReports);
        break;
      }
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to update settings for this app');
      setIsLoading(false);
    }
  };

  const handleRemoveAppClick = () => {
    setIsLoading(true);
    try {
      setIsRemoveAppDialogOpen(false);
      raiseDeletePermissionRequest();
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to delete app permission for this user');
      setIsLoading(false);
    }
  };

  const canShowAppSettings = (appId) => {
    return appSettings.hasOwnProperty(appId);
  };

  const getViewAppCard = (app) => {
    return (
      <Card
        key={app.appId}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 3,
          m: 2,
          mb: 0,
          backgroundImage: 'linear-gradient(45deg, #144272, #2C74B3)',
          '&:hover': {
            backgroundPosition: 'right'
          },
          backgroundSize: '150%',
          transition: '0.6s'
        }}>
        <CardHeader sx={{ color: '#FFF' }}
          title={getViewCardTitle(app)}
          action={
            <React.Fragment>
              {canShowAppSettings(app.appId) ? (
                <IconButton aria-label="Settings" sx={{ color: 'white' }} onClick={() => handleSettingsClick(app.appId)}>
                  <SettingsIcon />
                </IconButton>
              ) : ''}
              <IconButton aria-label="Edit" sx={{ color: 'white' }} onClick={() => handleEditClick(app.appId)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="Delete" sx={{ color: 'white' }} onClick={() => handleRemoveAppDialogOpen(app.appId)}>
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label="Collapse/Expand" sx={{ color: 'white' }} onClick={() => handleCardExpansion(app.appId)}>
                {expandedAppId == app.appId ? <ExpandLessIcon/> : <ExpandMoreIcon />}
              </IconButton>
            </React.Fragment>
          }
        />
        {expandedAppId == app.appId ? (
          <CardContent>
            <Grid
              container
              sx={{ pb: 0 }}
              columnSpacing={2}
            >
              {getAccessibleFeatures(app.features).map((featureGroup) => (
                <Grid item container key={featureGroup.name} xs={12} sm={6} lg={6} alignContent={'flex-start'}>
                  <Grid item xs={12}>
                    <Typography variant='h6' sx={{ color: '#B9B9B9', mb: 0.5 }}>
                      {featureGroup.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {featureGroup.features.map((feature) => (
                      (feature.is_enabled ?
                        <Chip key={feature.name} sx={{ backgroundColor: '#FFF', p: 1, color: 'primary.main', m: 0.5 }}
                          size="small" label={feature.name}/> : '')
                    ))}
                  </Grid>
                </Grid>
              ))}
              <Grid item container xs={12} sx={{ mt: 2 }}alignContent={'flex-start'}>
                <Grid item xs={12}>
                  <Typography variant='h6' sx={{ color: '#B9B9B9', mb: 0.5 }}>
                    Allowed Shops
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {app.shops.map((shop) => (
                    <Chip key={shop.shop_name} sx={{ backgroundColor: '#FDE767', p: 1, color: '#000', m: 0.5 }}
                      size="small" label={shop.shop_name}/>)
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        ) : ''}
      </Card>
    );
  };

  const isParentFeatureEnabled = (appId, featureId) => {
    const features = appPermissions[appId].features;
    const index = features.findIndex((feature) => feature.feature_id == featureId);
    if (index==0) {
      return false;
    }
    for (let i = index - 1; i >= 0; i--) {
      if (features[index].feature_group == features[i].feature_group) {
        return features[i].is_enabled;
      }
    }
    return false;
  };

  const getFeatureContentForEdit = (app) => {
    if (app.roleId == 2) {
      return (getAccessibleFeatures(app.features).map((featureGroup) => (
        <Grid item container key={featureGroup.name} xs={12} sm={6} lg={6} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <Typography variant='h6' sx={{ color: '#000', mb: 0.5 }}>
              {featureGroup.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {featureGroup.features.map((feature) => (
              <FormControlLabel
                control={
                  <Checkbox key={feature.name} checked={feature.is_enabled}
                    disabled={isParentFeatureEnabled(app.appId, feature.id)}
                    onChange={(event) => handleCustomRoleCheckBoxChange(app.appId, feature.id, event)} />
                }
                key={feature.name} label={feature.name}/>
            ))}
          </Grid>
        </Grid>
      )));
    } else {
      return (getAccessibleFeatures(app.features).map((featureGroup) => (
        <Grid item container key={featureGroup.name} xs={12} sm={6} lg={6} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <Typography variant='h6' sx={{ color: '#000', mb: 0.5 }}>
              {featureGroup.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {featureGroup.features.map((feature) => (
              (feature.is_enabled ?
                <Chip key={feature.name} sx={{ backgroundColor: 'primary.main', p: 1, color: '#FFF', m: 0.5 }}
                  size="small" label={feature.name}/> : '')
            ))}
          </Grid>
        </Grid>
      )));
    }
  };

  const isShopSelected = (shopId, appShops) => {
    if (appShops == undefined) {
      return false;
    }
    return appShops.some((obj) => {
      return obj.shop_id === shopId;
    });
  };

  const getShopContentForEdit = (app) => {
    if (allShops.length > 0) {
      return (
        <Grid item container xs={12} sx={{ mt: 3 }} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <Typography variant='h6' sx={{ color: '#000', mb: 0.5 }}>
              Allowed Shops
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {allShops.map((shop) => (
              <FormControlLabel
                control={
                  <Checkbox key={shop.shop_id} checked={isShopSelected(shop.shop_id, app.shops)}
                    onChange={(event) => handleShopCheckBoxChange(app.appId, shop.shop_id, event)} />
                }
                key={shop.shop_id} label={shop.shop_name}/>
            ))}
          </Grid>
        </Grid>
      );
    }
  };

  const getEditAppCard = (app) => {
    return (
      <Card
        key={app.appId}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 3,
          m: 2,
          mb: 0,
          border: '1px solid #000'
        }}>
        <CardHeader sx={{ color: '#000' }}
          title={getEditCardTitle(app)}
          action={
            <React.Fragment>
              <IconButton aria-label="Cancel" sx={{ color: 'red' }} onClick={() => handleCancelClick(app.appId)}>
                <CancelIcon/>
              </IconButton>
              <IconButton aria-label="Save" sx={{ color: '#205295' }} onClick={() => handleSaveClick(app.appId)}>
                <SaveIcon />
              </IconButton>
            </React.Fragment>
          }
        />
        <CardContent>
          <Grid
            container
            sx={{ pb: 0 }}
            columnSpacing={2}
            rowSpacing={2}
          >
            {app.roleId == -1 ? '' :
              app.features.length > 0 ?
                getFeatureContentForEdit(app) :
                <Typography variant='h6' sx={{ px: 3, color: 'red' }}>
                  No features enabled for this role
                </Typography>
            }
            {getShopContentForEdit(app)}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const getSettingsAppCard = (app) => {
    switch (app.appId) {
    case 'DISCOUNT':
      return getDiscountSettingsAppCard(app);
    case 'REPORTS':
      return getReportSettingsAppCard(app);
    default:
      return '';
    }
  };

  const handleDiscountSettingsValueChange = (appId, shopId, event) => {
    const settingsArr = cloneDeep(appSettings[appId]);
    settingsArr
      .filter((setting) => setting.shop_id == shopId)[0][event.target.name] = event.target.value === '' ? 0 : Number(event.target.value);
    setAppSettings({ ...appSettings, [appId]: settingsArr });
  };

  const handleReportSettingsValueChange = (appId, shopId, markId, event) => {
    const settingsArr = cloneDeep(appSettings[appId]);
    const shop = settingsArr.filter((setting) => setting.shop_id == shopId)[0];
    shop.allowed_marks.filter((mark) => mark.item_id == markId)[0].is_allowed = event.target.checked;
    setAppSettings({ ...appSettings, [appId]: settingsArr });
  };

  const handleSelectAllForReports = (appId, shopId, isChecked) => {
    const settingsArr = cloneDeep(appSettings[appId]);
    const shop = settingsArr.filter((setting) => setting.shop_id == shopId)[0];
    shop.allowed_marks.map((mark) => mark.is_allowed = isChecked);
    setAppSettings({ ...appSettings, [appId]: settingsArr });
  };

  const getDiscountSettingsContentForEdit = (appId) => {
    if (appSettings[appId] !== undefined) {
      return (
        <Grid item container xs={12} sx={{ mt: 1 }} alignContent={'flex-start'}>
          <Grid item xs={12} sx={{ p: 1, ml: 2, mb: 0.5 }} container justifyContent='space-between'>
            <Grid item xs={3}>
              <Typography variant='h6' sx={{ color: '#000' }}>
                Shop
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='h6' sx={{ color: '#000' }}>
                Non Cut Piece %
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='h6' sx={{ color: '#000' }}>
                Cut Piece %
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='h6' sx={{ color: '#000' }}>
                Staff %
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='h6' sx={{ color: '#000' }}>
                Daily Count
              </Typography>
            </Grid>
          </Grid>
          {appSettings[appId].map((setting) => (
            <Grid item xs={12} sx={{ p: 1, ml: 2 }} key={setting.shop_id} container justifyContent='space-between'>
              <Grid item xs={3}>
                <Typography variant='h6'>
                  {setting.shop_name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Input
                  value={setting.percent_allowed}
                  name='percent_allowed'
                  onChange={(event) => handleDiscountSettingsValueChange(appId, setting.shop_id, event)}
                  size="small"
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                    type: 'number',
                    inputMode: 'numeric',
                    pattern: '[0-9\.]*'
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  value={setting.c_percent_allowed}
                  onChange={(event) => handleDiscountSettingsValueChange(appId, setting.shop_id, event)}
                  size="small"
                  name='c_percent_allowed'
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                    type: 'number',
                    inputMode: 'numeric',
                    pattern: '[0-9\.]*'
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  value={setting.s_percent_allowed}
                  onChange={(event) => handleDiscountSettingsValueChange(appId, setting.shop_id, event)}
                  size="small"
                  name='s_percent_allowed'
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                    type: 'number',
                    inputMode: 'numeric',
                    pattern: '[0-9\.]*'
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  value={setting.daily_allowed_count}
                  onChange={(event) => handleDiscountSettingsValueChange(appId, setting.shop_id, event)}
                  size="small"
                  name='daily_allowed_count'
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    max: 100,
                    type: 'tel'
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  const getDiscountSettingsAppCard = (app) => {
    return (
      <Card
        key={app.appId}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 3,
          m: 2,
          mb: 0,
          border: '1px solid #000'
        }}>
        <CardHeader sx={{ color: '#000' }}
          title={getSettingsCardTitle(app)}
          action={
            <React.Fragment>
              <IconButton aria-label="Cancel" sx={{ color: 'red' }} onClick={() => handleCancelSettingsClick(app.appId)}>
                <CancelIcon/>
              </IconButton>
              <IconButton aria-label="Save" sx={{ color: '#205295' }} onClick={() => handleSaveSettingsClick(app.appId)}>
                <SaveIcon />
              </IconButton>
            </React.Fragment>
          }
        />
        <CardContent>
          <Grid
            container
            sx={{ pb: 0 }}
            columnSpacing={2}
          >
            {getDiscountSettingsContentForEdit(app.appId)}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const getReportSettingsContentForEdit = (appId) => {
    if (appSettings[appId] !== undefined) {
      return (
        <Grid item container xs={12} sx={{ mt: 1 }} alignContent={'flex-start'}>
          {appSettings[appId].map((setting) => (
            <Grid item xs={12} sx={{ p: 1, ml: 2 }} key={setting.shop_id} container justifyContent='space-between'>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <Typography variant='h6' sx={{ alignItems: 'center', display: 'flex' }}>
                    {setting.shop_name}
                  </Typography>
                  <Tooltip title='Select All'>
                    <IconButton tabIndex={-1}
                      onClick={() => handleSelectAllForReports(appId, setting.shop_id, true)}
                    >
                      <SelectAllIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Deselect All'>
                    <IconButton tabIndex={-1}
                      onClick={() => handleSelectAllForReports(appId, setting.shop_id, false)}
                    >
                      <DeselectIcon/>
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {setting.allowed_marks.map((mark) => (
                  <FormControlLabel
                    control={
                      <Checkbox key={mark.item_id} checked={mark.is_allowed}
                        onChange={(event) => handleReportSettingsValueChange(appId, setting.shop_id, mark.item_id, event)} />
                    }
                    key={mark.item_id} label={mark.mark_name}/>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  const getReportSettingsAppCard = (app) => {
    return (
      <Card
        key={app.appId}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 3,
          m: 2,
          mb: 0,
          border: '1px solid #000'
        }}>
        <CardHeader sx={{ color: '#000' }}
          title={getSettingsCardTitle(app)}
          action={
            <React.Fragment>
              <IconButton aria-label="Cancel" sx={{ color: 'red' }} onClick={() => handleCancelSettingsClick(app.appId)}>
                <CancelIcon/>
              </IconButton>
              <IconButton aria-label="Save" sx={{ color: '#205295' }} onClick={() => handleSaveSettingsClick(app.appId)}>
                <SaveIcon />
              </IconButton>
            </React.Fragment>
          }
        />
        <CardContent>
          <Grid
            container
            sx={{ pb: 0 }}
            columnSpacing={2}
          >
            {getReportSettingsContentForEdit(app.appId)}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const handleRemoveAppDialogOpen = (appId) => {
    setSelectedAppForDelete(appId);
    setIsRemoveAppDialogOpen(true);
  };

  const handleRemoveAppUserDialogClose = () => {
    setIsRemoveAppDialogOpen(false);
  };

  const handleAddAppClick = () => {
    let appObj = {
      appId: -1,
      appName: ''
    };
    if (allApps.length - Object.keys(appPermissions).length == 1) {
      allApps.map((app) => {
        if (appPermissions[app.app_id] == undefined) {
          appObj = {
            appId: app.app_id,
            appName: app.app_name
          };
        }
      });
    }
    const obj = {
      appId: appObj.appId,
      appName: appObj.appName,
      roleId: -1,
      roleName: '',
      features: [],
      shops: [],
      isNew: true
    };

    if (obj.appId != -1 && appRoles[obj.appId] == undefined) {
      raiseGetAppRolesRequest(obj.appId);
    }
    setAppPermissions({ ...appPermissions, [appObj.appId]: obj });
    setExpandedAppId(undefined);
    setSelectedAppId(appObj.appId);
  };

  const getRemoveAppConfirmationDialog = () => {
    return (
      <Box>
        <Dialog
          open={isRemoveAppDialogOpen}
          PaperProps={{
            style: { borderRadius: 20 }
          }}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Remove App Permission</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to remove this app &apos;{selectedAppForDelete}&apos; for this user?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              size='small'
              color='primary'
              onClick={handleRemoveAppUserDialogClose}
              sx={{ mr: 8, borderRadius: 15, fontSize: '13px' }}
            >
              Cancel
            </Button>
            <Button
              size='small'
              color='primary'
              variant='contained'
              onClick={() => handleRemoveAppClick()}
              sx={{
                borderRadius: 15,
                fontSize: '12px'
              }}
            >
              Remove App
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const getResetPasswordConfirmationDialog = () => {
    return (
      <Box>
        <Dialog
          open={isResetPasswordDialogOpen}
          PaperProps={{
            style: { borderRadius: 20 }
          }}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Reset Password</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to reset password for the selected user?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              size='small'
              color='primary'
              onClick={() => setIsResetPasswordDialogOpen(false)}
              sx={{ mr: 8, borderRadius: 15, fontSize: '13px' }}
            >
              Cancel
            </Button>
            <Button
              size='small'
              color='primary'
              variant='contained'
              onClick={() => handleResetPassswordClick()}
              sx={{
                borderRadius: 15,
                fontSize: '12px'
              }}
            >
              Reset Password
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  return (
    <React.Fragment>
      {getUserDetails()}
      {userInfo.is_active ? getPermissionCards() : ''}
      {userInfo.is_active && allApps.length > Object.keys(appPermissions).length ? (
        <Grid container>
          <Grid item xs={12}>
            <IconButton aria-label="Add App" sx={{ ml: 1, mt: 1, color: 'primary.main' }} onClick={() => handleAddAppClick()}>
              <AddIcon sx={{ fontSize: '20px' }} />
              <Typography variant='h6'>
                Add App
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      ) : ''}
      {getSnackbar}
      {getRemoveAppConfirmationDialog()}
      {getResetPasswordConfirmationDialog()}
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

GrantAppPermisssion.propTypes = {
  userId: PropTypes.string,
  onSuccessfulCommit: PropTypes.func
};

export default GrantAppPermisssion;
